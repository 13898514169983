export enum City {
  GRENOBLE = "GRENOBLE", // the value is the same as the key because oh graphql
  LYON = "LYON",
  DIJON = "DIJON",
  STRASBOURG = "STRASBOURG",
}

export const CityNames: { [key in City]: string } = {
  [City.GRENOBLE]: "Grenoble",
  [City.LYON]: "Lyon",
  [City.DIJON]: "Dijon",
  [City.STRASBOURG]: "Strasbourg",
};

export const CityArray: { id: City; name: string }[] = Object.entries(
  CityNames
).map(([id, name]) => ({ id: id as City, name }));
