import {
  Partner as PartnerInterface,
  Event as EventInterface,
} from "@emblem/api";
import { Event, Partner } from "@emblem/api";
import { computed, reactive, ref, provide, watch } from "vue";
import type { Ref } from "vue";
import { Preferences } from "@capacitor/preferences";

interface Collection<T> extends Function {
  new (...params: any[]): T;
  prototype: T;
  getAll(): Promise<T[]>;
}

const content: {
  partners?: Record<string, Partner>;
  events?: Record<string, Event>;
} = {};

export function provideContent<T extends { _id: string }>(
  name: string,
  constructor: Collection<T>,
  sort?: (a: T, b: T) => number
) {
  const _list = ref<T[]>([]) as Ref<T[]>;
  const list = computed(() =>
    sort ? Array.from(_list.value).sort(sort) : _list.value
  );
  const record = reactive<Record<string, T>>({});
  // @ts-expect-error this is fine
  if (name === "partners" && constructor === Partner) content.partners = record;
  // @ts-expect-error this is fine
  if (name === "events" && constructor === Event) content.events = record;
  watch(record, (val) => (_list.value = Object.values(val)));

  async function load() {
    const items = await constructor.getAll();
    _list.value = items;
    items.forEach((item: T) => (record[item._id] = item));
  }

  provide(`${name}:record`, record);
  provide(`${name}:list`, list);
  // provide doesn't work well in async context so we return a load function that we can call later
  return load;
}

let _handler: () => Promise<void> = () => Promise.resolve();

export function setReloadContentHandler(handler: () => Promise<void>) {
  _handler = handler;
}

export async function reloadContent() {
  console.log("reloading content");
  await _handler();
  console.log("content reloaded");
}

const CONTENT_CACHE_VERSION = "1.0.0";

export async function backupContent() {
  const json = JSON.stringify({ version: CONTENT_CACHE_VERSION, content });
  await Preferences.set({ key: "content", value: json });
}

export async function restoreContent() {
  const { value: json } = await Preferences.get({ key: "content" });
  if (json) {
    const data: any = JSON.parse(json);
    if (data.version !== CONTENT_CACHE_VERSION) {
      // delete old cache
      await Preferences.remove({ key: "content" });
    } else {
      const { partners, events } = data.content as {
        partners: Record<string, PartnerInterface>;
        events: Record<string, EventInterface>;
      };
      Object.entries(partners).forEach(([key, val]) => {
        content.partners![key] = new Partner(val);
      });
      Object.entries(events).forEach(([key, val]) => {
        content.events![key] = new Event(val);
      });
    }
  }
}
