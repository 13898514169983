import type { City } from "@emblem/common";

export interface Store {
  reclaimedGifts: Set<string>;
  city: City | null;
}
export interface StoreJSON {
  reclaimedGifts: Array<string>;
  city: City;
}

export const store: Store = {
  reclaimedGifts: new Set<string>(),
  city: null,
};

export function initializeStore(handler: (store: Store) => void) {
  handler(store);
}
