import { initializeStore } from "@emblem/api";
import type { Store, StoreJSON } from "@emblem/api";

const STORE_KEY = "emblem:store";

import { Preferences } from "@capacitor/preferences";

let STORE: Store;
let STORE_LOAD_PROMISE: Promise<void>;

export async function getStore() {
  await STORE_LOAD_PROMISE;
  return STORE;
}

export async function clearStore() {
  await Preferences.remove({ key: STORE_KEY });
}

export async function saveStore() {
  await Preferences.set({
    key: STORE_KEY,
    value: JSON.stringify({ ...STORE, version: "1.0.0" }),
  });
}

export async function loadStore() {
  const { value: json } = await Preferences.get({ key: STORE_KEY });
  if (json) {
    const jsonData: any = JSON.parse(json);
    if (jsonData.version === undefined) {
      console.log("converting store to v1.0.0", jsonData);
      // first version of the app didn't have version in the store
      jsonData.city = null;
      // now store data is like v1 json data
      jsonData.version = "1.0.0";
    }
    // TODO: check for unrecognized version and try to convert if possible
    try {
      const data: StoreJSON & { version: string } = jsonData;
      // after this line, we can assume that data is a StoreJSON (latest version)
      data.reclaimedGifts.forEach((val) => STORE.reclaimedGifts.add(val));
      STORE.city = data.city;
    } catch (e) {
      console.error("Store is corrupted, got error");
      console.error(e);
      console.log("resetting store");
      await clearStore();
    }
  }
}

initializeStore((store) => {
  STORE = store;
  class PersistentSet<T> extends Set<T> {
    add(value: T) {
      const res = super.add.call(this, value);
      saveStore();
      return res;
    }
    delete(value: T) {
      const res = super.delete.call(this, value);
      saveStore();
      return res;
    }
    clear() {
      super.clear.call(this);
      saveStore();
    }
    toJSON() {
      return Array.from(this.values());
    }
  }
  store.reclaimedGifts = new PersistentSet<string>();

  STORE_LOAD_PROMISE = loadStore();
});
