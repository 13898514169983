import type { StyleSpecification } from "maplibre-gl";

import sources from "./sources";
import layers from "./layers";
import { createURL } from "./url";

const style: StyleSpecification = {
  version: 8,
  id: "bright",
  name: "Bright",
  sources,
  layers,
  metadata: {
    "maptiler:copyright":
      "This style was generated on MapTiler Cloud. Usage outside of MapTiler Cloud requires valid MapTiler Data Package: https://www.maptiler.com/data/package/ -- please contact us.",
  },
  glyphs: createURL("/maps/fonts/{fontstack}/{range}.pbf"),
  sprite: createURL("/maps/styles/bright/sprite"),
  bearing: 0,
  pitch: 0,
  center: [0, 0],
  zoom: 1,
} as unknown as StyleSpecification;

export default style;
