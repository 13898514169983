import { createURL } from "./url";

export default {
  openmaptiles: {
    type: "vector",
    id: "maptiler-planet",
    bounds: [-180, -85.0511, 180, 85.0511],
    center: [0, 0, 1],
    format: "pbf",
    version: "3.13.1",
    tilejson: "2.1.0",
    generator: "MapTiler Data",
    planettime: "1653696000000",
    attribution:
      '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    description:
      "As the main global tileset contains layers for the general-purpose maps. In the comparison with OpenMapTiles, it contains more buildings and landcover in North America, controlled borders, and global landcover. This data are released in month cycles with quality control.",
    pixel_scale: "256",
    name: "MapTiler Planet",
    minzoom: 0,
    maxzoom: 15,
    vector_layers: [
      {
        id: "water",
        minzoom: 0,
        maxzoom: 15,
        description:
          "Water polygons representing oceans and lakes. Covered watered areas are excluded (`covered=yes`).\nOn low zoom levels all water originates from Natural Earth. To get a more correct display of the south pole you should also\nstyle the covering ice shelves over the water.\nOn higher zoom levels water polygons from [OpenStreetMapData](http://osmdata.openstreetmap.de/) are used.\nThe polygons are split into many smaller polygons to improve rendering performance.\nThis however can lead to less rendering options in clients since these boundaries show up. So you might not be\nable to use border styling for ocean water features.",
        fields: {
          id: "Number",
          class: "String",
          brunnel: "String",
          intermittent: "Number",
        },
      },
      {
        id: "waterway",
        minzoom: 0,
        maxzoom: 15,
        description:
          "OpenStreetMap [waterways](https://wiki.openstreetmap.org/wiki/Waterways) for higher zoom levels (z9 and more)\nand Natural Earth rivers and lake centerlines for low zoom levels (z3 - z8).\nLinestrings without a name or which are too short are filtered\nout at low zoom levels.\nTill z11 there is `river` class only, in z12 there is also `canal` generated,\nstarting z13 there is no generalization according to `class` field applied.\nWaterways do not have a `subclass` field.",
        fields: {
          name: "String",
          class: "String",
          brunnel: "String",
          "name:am": "String",
          "name:ar": "String",
          "name:az": "String",
          "name:be": "String",
          "name:bg": "String",
          "name:br": "String",
          "name:bs": "String",
          "name:ca": "String",
          "name:co": "String",
          "name:cs": "String",
          "name:cy": "String",
          "name:da": "String",
          "name:de": "String",
          "name:el": "String",
          "name:en": "String",
          "name:eo": "String",
          "name:es": "String",
          "name:et": "String",
          "name:eu": "String",
          "name:fi": "String",
          "name:fr": "String",
          "name:fy": "String",
          "name:ga": "String",
          "name:gd": "String",
          "name:he": "String",
          "name:hi": "String",
          "name:hr": "String",
          "name:hu": "String",
          "name:hy": "String",
          "name:id": "String",
          "name:is": "String",
          "name:it": "String",
          "name:ja": "String",
          "name:ka": "String",
          "name:kk": "String",
          "name:kn": "String",
          "name:ko": "String",
          "name:ku": "String",
          "name:la": "String",
          "name:lb": "String",
          "name:lt": "String",
          "name:lv": "String",
          "name:mk": "String",
          "name:ml": "String",
          "name:mt": "String",
          "name:nl": "String",
          "name:no": "String",
          "name:oc": "String",
          "name:pl": "String",
          "name:pt": "String",
          "name:rm": "String",
          "name:ro": "String",
          "name:ru": "String",
          "name:sk": "String",
          "name:sl": "String",
          "name:sq": "String",
          "name:sr": "String",
          "name:sv": "String",
          "name:ta": "String",
          "name:te": "String",
          "name:th": "String",
          "name:tr": "String",
          "name:uk": "String",
          "name:zh": "String",
          name_de: "String",
          name_en: "String",
          name_int: "String",
          "name:ja_rm": "String",
          "name:latin": "String",
          intermittent: "Number",
          "name:ja-Hira": "String",
          "name:ja-Latn": "String",
          "name:ja_kana": "String",
          "name:ko-Latn": "String",
          "name:sr-Latn": "String",
          "name:nonlatin": "String",
        },
      },
      {
        id: "landcover",
        minzoom: 0,
        maxzoom: 15,
        description:
          "Landcover is used to describe the physical material at the surface of the earth. At lower zoom levels this is\nfrom Natural Earth data for glaciers and ice shelves and at higher zoom levels the landcover is [implied by OSM tags](http://wiki.openstreetmap.org/wiki/Landcover). The most common use case for this layer\n  is to style wood (`class=wood`) and grass (`class=grass`) areas.",
        fields: { class: "String", subclass: "String" },
      },
      {
        id: "landuse",
        minzoom: 0,
        maxzoom: 15,
        description:
          "Landuse is used to describe use of land by humans. At lower zoom levels this is\nfrom Natural Earth data for residential (urban) areas and at higher zoom levels mostly OSM `landuse` tags.",
        fields: { class: "String" },
      },
      {
        id: "mountain_peak",
        minzoom: 0,
        maxzoom: 15,
        description:
          "[Natural peaks](http://wiki.openstreetmap.org/wiki/Tag:natural%3Dpeak)",
        fields: {
          ele: "Number",
          name: "String",
          rank: "Number",
          class: "String",
          ele_ft: "Number",
          osm_id: "Number",
          "name:am": "String",
          "name:ar": "String",
          "name:az": "String",
          "name:be": "String",
          "name:bg": "String",
          "name:br": "String",
          "name:bs": "String",
          "name:ca": "String",
          "name:co": "String",
          "name:cs": "String",
          "name:cy": "String",
          "name:da": "String",
          "name:de": "String",
          "name:el": "String",
          "name:en": "String",
          "name:eo": "String",
          "name:es": "String",
          "name:et": "String",
          "name:eu": "String",
          "name:fi": "String",
          "name:fr": "String",
          "name:fy": "String",
          "name:ga": "String",
          "name:gd": "String",
          "name:he": "String",
          "name:hi": "String",
          "name:hr": "String",
          "name:hu": "String",
          "name:hy": "String",
          "name:id": "String",
          "name:is": "String",
          "name:it": "String",
          "name:ja": "String",
          "name:ka": "String",
          "name:kk": "String",
          "name:kn": "String",
          "name:ko": "String",
          "name:ku": "String",
          "name:la": "String",
          "name:lb": "String",
          "name:lt": "String",
          "name:lv": "String",
          "name:mk": "String",
          "name:ml": "String",
          "name:mt": "String",
          "name:nl": "String",
          "name:no": "String",
          "name:oc": "String",
          "name:pl": "String",
          "name:pt": "String",
          "name:rm": "String",
          "name:ro": "String",
          "name:ru": "String",
          "name:sk": "String",
          "name:sl": "String",
          "name:sq": "String",
          "name:sr": "String",
          "name:sv": "String",
          "name:ta": "String",
          "name:te": "String",
          "name:th": "String",
          "name:tr": "String",
          "name:uk": "String",
          "name:zh": "String",
          name_de: "String",
          name_en: "String",
          name_int: "String",
          "name:ja_rm": "String",
          "name:latin": "String",
          customary_ft: "Number",
          "name:ja-Hira": "String",
          "name:ja-Latn": "String",
          "name:ja_kana": "String",
          "name:ko-Latn": "String",
          "name:sr-Latn": "String",
          "name:nonlatin": "String",
        },
      },
      {
        id: "park",
        minzoom: 0,
        maxzoom: 15,
        description:
          "The park layer contains parks from OpenStreetMap tagged with\n[`boundary=national_park`](http://wiki.openstreetmap.org/wiki/Tag:boundary%3Dnational_park),\n[`boundary=protected_area`](http://wiki.openstreetmap.org/wiki/Tag:boundary%3Dprotected_area),\nor [`leisure=nature_reserve`](http://wiki.openstreetmap.org/wiki/Tag:leisure%3Dnature_reserve).",
        fields: {
          name: "String",
          rank: "Number",
          class: "String",
          "name:am": "String",
          "name:ar": "String",
          "name:az": "String",
          "name:be": "String",
          "name:bg": "String",
          "name:br": "String",
          "name:bs": "String",
          "name:ca": "String",
          "name:co": "String",
          "name:cs": "String",
          "name:cy": "String",
          "name:da": "String",
          "name:de": "String",
          "name:el": "String",
          "name:en": "String",
          "name:eo": "String",
          "name:es": "String",
          "name:et": "String",
          "name:eu": "String",
          "name:fi": "String",
          "name:fr": "String",
          "name:fy": "String",
          "name:ga": "String",
          "name:gd": "String",
          "name:he": "String",
          "name:hi": "String",
          "name:hr": "String",
          "name:hu": "String",
          "name:hy": "String",
          "name:id": "String",
          "name:is": "String",
          "name:it": "String",
          "name:ja": "String",
          "name:ka": "String",
          "name:kk": "String",
          "name:kn": "String",
          "name:ko": "String",
          "name:ku": "String",
          "name:la": "String",
          "name:lb": "String",
          "name:lt": "String",
          "name:lv": "String",
          "name:mk": "String",
          "name:ml": "String",
          "name:mt": "String",
          "name:nl": "String",
          "name:no": "String",
          "name:oc": "String",
          "name:pl": "String",
          "name:pt": "String",
          "name:rm": "String",
          "name:ro": "String",
          "name:ru": "String",
          "name:sk": "String",
          "name:sl": "String",
          "name:sq": "String",
          "name:sr": "String",
          "name:sv": "String",
          "name:ta": "String",
          "name:te": "String",
          "name:th": "String",
          "name:tr": "String",
          "name:uk": "String",
          "name:zh": "String",
          name_de: "String",
          name_en: "String",
          name_int: "String",
          "name:ja_rm": "String",
          "name:latin": "String",
          "name:ja-Hira": "String",
          "name:ja-Latn": "String",
          "name:ja_kana": "String",
          "name:ko-Latn": "String",
          "name:sr-Latn": "String",
          "name:nonlatin": "String",
        },
      },
      {
        id: "boundary",
        minzoom: 0,
        maxzoom: 15,
        description:
          "Contains administrative boundaries as linestrings.\nOSM boundaries ([`boundary=administrative`](http://wiki.openstreetmap.org/wiki/Tag:boundary%3Dadministrative))\nare present from z0 to z14.\nOSM data contains several [`admin_level`](http://wiki.openstreetmap.org/wiki/Tag:boundary%3Dadministrative#admin_level)\nbut for most styles it makes sense to just style `admin_level=2` and `admin_level=4`.",
        fields: {
          adm0_l: "String",
          adm0_r: "String",
          disputed: "Number",
          maritime: "Number",
          claimed_by: "String",
          admin_level: "Number",
          disputed_name: "String",
        },
      },
      {
        id: "aeroway",
        minzoom: 0,
        maxzoom: 15,
        description:
          "Aeroway polygons based of OpenStreetMap [aeroways](http://wiki.openstreetmap.org/wiki/Aeroways).\nAirport buildings are contained in the **building** layer but all\nother airport related polygons can be found in the **aeroway** layer.",
        fields: { ref: "String", class: "String" },
      },
      {
        id: "transportation",
        minzoom: 0,
        maxzoom: 15,
        description:
          "**transportation** contains roads, railways, aerial ways, and shipping\n lines.\nThis layer is directly derived from the OSM road hierarchy.\nAt lower zoom levels major highways from Natural Earth are used.\nIt contains all roads from motorways to primary, secondary and\ntertiary roads to residential roads and\nfoot paths. Styling the roads is the most essential part of the map.\nThe `transportation` layer also contains polygons for features like plazas.",
        fields: {
          foot: "String",
          ramp: "Number",
          toll: "Number",
          class: "String",
          horse: "String",
          layer: "Number",
          level: "Number",
          access: "String",
          indoor: "Number",
          oneway: "Number",
          bicycle: "String",
          brunnel: "String",
          network: "String",
          service: "String",
          surface: "String",
          subclass: "String",
          mtb_scale: "String",
          expressway: "Number",
        },
      },
      {
        id: "building",
        minzoom: 0,
        maxzoom: 15,
        description:
          "All [OSM Buildings](http://wiki.openstreetmap.org/wiki/Buildings). All building tags are imported ([`building=*`](http://wiki.openstreetmap.org/wiki/Key:building)).\nOnly buildings with tag location:underground are excluded.",
        fields: {
          colour: "String",
          osm_id: "Number",
          hide_3d: "Boolean",
          render_height: "Number",
          render_min_height: "Number",
        },
      },
      {
        id: "water_name",
        minzoom: 0,
        maxzoom: 15,
        description:
          "Lake center lines for labelling lake bodies.\nThis is based of the [osm-lakelines](https://github.com/openmaptiles/osm-lakelines) project\nwhich derives nice centerlines from OSM water bodies. Only the most important lakes contain labels.",
        fields: {
          name: "String",
          class: "String",
          osm_id: "Number",
          "name:am": "String",
          "name:ar": "String",
          "name:az": "String",
          "name:be": "String",
          "name:bg": "String",
          "name:br": "String",
          "name:bs": "String",
          "name:ca": "String",
          "name:co": "String",
          "name:cs": "String",
          "name:cy": "String",
          "name:da": "String",
          "name:de": "String",
          "name:el": "String",
          "name:en": "String",
          "name:eo": "String",
          "name:es": "String",
          "name:et": "String",
          "name:eu": "String",
          "name:fi": "String",
          "name:fr": "String",
          "name:fy": "String",
          "name:ga": "String",
          "name:gd": "String",
          "name:he": "String",
          "name:hi": "String",
          "name:hr": "String",
          "name:hu": "String",
          "name:hy": "String",
          "name:id": "String",
          "name:is": "String",
          "name:it": "String",
          "name:ja": "String",
          "name:ka": "String",
          "name:kk": "String",
          "name:kn": "String",
          "name:ko": "String",
          "name:ku": "String",
          "name:la": "String",
          "name:lb": "String",
          "name:lt": "String",
          "name:lv": "String",
          "name:mk": "String",
          "name:ml": "String",
          "name:mt": "String",
          "name:nl": "String",
          "name:no": "String",
          "name:oc": "String",
          "name:pl": "String",
          "name:pt": "String",
          "name:rm": "String",
          "name:ro": "String",
          "name:ru": "String",
          "name:sk": "String",
          "name:sl": "String",
          "name:sq": "String",
          "name:sr": "String",
          "name:sv": "String",
          "name:ta": "String",
          "name:te": "String",
          "name:th": "String",
          "name:tr": "String",
          "name:uk": "String",
          "name:zh": "String",
          name_de: "String",
          name_en: "String",
          name_int: "String",
          "name:ja_rm": "String",
          "name:latin": "String",
          intermittent: "Number",
          "name:ja-Hira": "String",
          "name:ja-Latn": "String",
          "name:ja_kana": "String",
          "name:ko-Latn": "String",
          "name:sr-Latn": "String",
          "name:nonlatin": "String",
        },
      },
      {
        id: "transportation_name",
        minzoom: 0,
        maxzoom: 15,
        description:
          "This is the layer for labelling the highways. Only highways that are named `name=*` and are long enough\nto place text upon appear. The OSM roads are stitched together if they contain the same name\nto have better label placement than having many small linestrings.\nFor motorways you should use the `ref` field to label them while for other roads you should use `name`.",
        fields: {
          ref: "String",
          name: "String",
          class: "String",
          layer: "Number",
          level: "Number",
          indoor: "Number",
          brunnel: "String",
          "name:am": "String",
          "name:ar": "String",
          "name:az": "String",
          "name:be": "String",
          "name:bg": "String",
          "name:br": "String",
          "name:bs": "String",
          "name:ca": "String",
          "name:co": "String",
          "name:cs": "String",
          "name:cy": "String",
          "name:da": "String",
          "name:de": "String",
          "name:el": "String",
          "name:en": "String",
          "name:eo": "String",
          "name:es": "String",
          "name:et": "String",
          "name:eu": "String",
          "name:fi": "String",
          "name:fr": "String",
          "name:fy": "String",
          "name:ga": "String",
          "name:gd": "String",
          "name:he": "String",
          "name:hi": "String",
          "name:hr": "String",
          "name:hu": "String",
          "name:hy": "String",
          "name:id": "String",
          "name:is": "String",
          "name:it": "String",
          "name:ja": "String",
          "name:ka": "String",
          "name:kk": "String",
          "name:kn": "String",
          "name:ko": "String",
          "name:ku": "String",
          "name:la": "String",
          "name:lb": "String",
          "name:lt": "String",
          "name:lv": "String",
          "name:mk": "String",
          "name:ml": "String",
          "name:mt": "String",
          "name:nl": "String",
          "name:no": "String",
          "name:oc": "String",
          "name:pl": "String",
          "name:pt": "String",
          "name:rm": "String",
          "name:ro": "String",
          "name:ru": "String",
          "name:sk": "String",
          "name:sl": "String",
          "name:sq": "String",
          "name:sr": "String",
          "name:sv": "String",
          "name:ta": "String",
          "name:te": "String",
          "name:th": "String",
          "name:tr": "String",
          "name:uk": "String",
          "name:zh": "String",
          name_de: "String",
          name_en: "String",
          network: "String",
          route_1: "String",
          route_2: "String",
          route_3: "String",
          route_4: "String",
          route_5: "String",
          route_6: "String",
          name_int: "String",
          subclass: "String",
          "name:ja_rm": "String",
          "name:latin": "String",
          ref_length: "Number",
          "name:ja-Hira": "String",
          "name:ja-Latn": "String",
          "name:ja_kana": "String",
          "name:ko-Latn": "String",
          "name:sr-Latn": "String",
          "name:nonlatin": "String",
        },
      },
      {
        id: "place",
        minzoom: 0,
        maxzoom: 15,
        description:
          "The place layer consists out of [countries](http://wiki.openstreetmap.org/wiki/Tag:place%3Dcountry),\n[states](http://wiki.openstreetmap.org/wiki/Tag:place%3Dstate), [cities](http://wiki.openstreetmap.org/wiki/Key:place)\nand [islands](https://wiki.openstreetmap.org/wiki/Tag:place%3Disland).\nApart from the roads this is also one of the more important layers to create a beautiful map.\nWe suggest you use different font styles and sizes to create a text hierarchy.",
        fields: {
          name: "String",
          rank: "Number",
          class: "String",
          iso_a2: "String",
          osm_id: "Number",
          capital: "Number",
          "name:am": "String",
          "name:ar": "String",
          "name:az": "String",
          "name:be": "String",
          "name:bg": "String",
          "name:br": "String",
          "name:bs": "String",
          "name:ca": "String",
          "name:co": "String",
          "name:cs": "String",
          "name:cy": "String",
          "name:da": "String",
          "name:de": "String",
          "name:el": "String",
          "name:en": "String",
          "name:eo": "String",
          "name:es": "String",
          "name:et": "String",
          "name:eu": "String",
          "name:fi": "String",
          "name:fr": "String",
          "name:fy": "String",
          "name:ga": "String",
          "name:gd": "String",
          "name:he": "String",
          "name:hi": "String",
          "name:hr": "String",
          "name:hu": "String",
          "name:hy": "String",
          "name:id": "String",
          "name:is": "String",
          "name:it": "String",
          "name:ja": "String",
          "name:ka": "String",
          "name:kk": "String",
          "name:kn": "String",
          "name:ko": "String",
          "name:ku": "String",
          "name:la": "String",
          "name:lb": "String",
          "name:lt": "String",
          "name:lv": "String",
          "name:mk": "String",
          "name:ml": "String",
          "name:mt": "String",
          "name:nl": "String",
          "name:no": "String",
          "name:oc": "String",
          "name:pl": "String",
          "name:pt": "String",
          "name:rm": "String",
          "name:ro": "String",
          "name:ru": "String",
          "name:sk": "String",
          "name:sl": "String",
          "name:sq": "String",
          "name:sr": "String",
          "name:sv": "String",
          "name:ta": "String",
          "name:te": "String",
          "name:th": "String",
          "name:tr": "String",
          "name:uk": "String",
          "name:zh": "String",
          name_de: "String",
          name_en: "String",
          name_int: "String",
          "name:ja_rm": "String",
          "name:latin": "String",
          "name:ja-Hira": "String",
          "name:ja-Latn": "String",
          "name:ja_kana": "String",
          "name:ko-Latn": "String",
          "name:sr-Latn": "String",
          "name:nonlatin": "String",
        },
      },
      {
        id: "housenumber",
        minzoom: 0,
        maxzoom: 15,
        description:
          "Everything in OpenStreetMap which contains a `addr:housenumber` tag useful for labelling housenumbers on a map.\nThis adds significant size to *z14*. For buildings the centroid of the building is used as housenumber.",
        fields: { housenumber: "String" },
      },
      {
        id: "poi",
        minzoom: 0,
        maxzoom: 15,
        description:
          "[Points of interests](http://wiki.openstreetmap.org/wiki/Points_of_interest) containing\na of a variety of OpenStreetMap tags. Mostly contains amenities, sport, shop and tourist POIs.",
        fields: {
          name: "String",
          rank: "Number",
          class: "String",
          layer: "Number",
          level: "Number",
          indoor: "Number",
          osm_id: "Number",
          "name:am": "String",
          "name:ar": "String",
          "name:az": "String",
          "name:be": "String",
          "name:bg": "String",
          "name:br": "String",
          "name:bs": "String",
          "name:ca": "String",
          "name:co": "String",
          "name:cs": "String",
          "name:cy": "String",
          "name:da": "String",
          "name:de": "String",
          "name:el": "String",
          "name:en": "String",
          "name:eo": "String",
          "name:es": "String",
          "name:et": "String",
          "name:eu": "String",
          "name:fi": "String",
          "name:fr": "String",
          "name:fy": "String",
          "name:ga": "String",
          "name:gd": "String",
          "name:he": "String",
          "name:hi": "String",
          "name:hr": "String",
          "name:hu": "String",
          "name:hy": "String",
          "name:id": "String",
          "name:is": "String",
          "name:it": "String",
          "name:ja": "String",
          "name:ka": "String",
          "name:kk": "String",
          "name:kn": "String",
          "name:ko": "String",
          "name:ku": "String",
          "name:la": "String",
          "name:lb": "String",
          "name:lt": "String",
          "name:lv": "String",
          "name:mk": "String",
          "name:ml": "String",
          "name:mt": "String",
          "name:nl": "String",
          "name:no": "String",
          "name:oc": "String",
          "name:pl": "String",
          "name:pt": "String",
          "name:rm": "String",
          "name:ro": "String",
          "name:ru": "String",
          "name:sk": "String",
          "name:sl": "String",
          "name:sq": "String",
          "name:sr": "String",
          "name:sv": "String",
          "name:ta": "String",
          "name:te": "String",
          "name:th": "String",
          "name:tr": "String",
          "name:uk": "String",
          "name:zh": "String",
          name_de: "String",
          name_en: "String",
          agg_stop: "Number",
          name_int: "String",
          subclass: "String",
          "name:ja_rm": "String",
          "name:latin": "String",
          "name:ja-Hira": "String",
          "name:ja-Latn": "String",
          "name:ja_kana": "String",
          "name:ko-Latn": "String",
          "name:sr-Latn": "String",
          "name:nonlatin": "String",
        },
      },
      {
        id: "aerodrome_label",
        minzoom: 0,
        maxzoom: 15,
        description:
          "[Aerodrome labels](http://wiki.openstreetmap.org/wiki/Tag:aeroway%3Daerodrome)",
        fields: {
          id: "Number",
          ele: "Number",
          iata: "String",
          icao: "String",
          name: "String",
          class: "String",
          ele_ft: "Number",
          "name:am": "String",
          "name:ar": "String",
          "name:az": "String",
          "name:be": "String",
          "name:bg": "String",
          "name:br": "String",
          "name:bs": "String",
          "name:ca": "String",
          "name:co": "String",
          "name:cs": "String",
          "name:cy": "String",
          "name:da": "String",
          "name:de": "String",
          "name:el": "String",
          "name:en": "String",
          "name:eo": "String",
          "name:es": "String",
          "name:et": "String",
          "name:eu": "String",
          "name:fi": "String",
          "name:fr": "String",
          "name:fy": "String",
          "name:ga": "String",
          "name:gd": "String",
          "name:he": "String",
          "name:hi": "String",
          "name:hr": "String",
          "name:hu": "String",
          "name:hy": "String",
          "name:id": "String",
          "name:is": "String",
          "name:it": "String",
          "name:ja": "String",
          "name:ka": "String",
          "name:kk": "String",
          "name:kn": "String",
          "name:ko": "String",
          "name:ku": "String",
          "name:la": "String",
          "name:lb": "String",
          "name:lt": "String",
          "name:lv": "String",
          "name:mk": "String",
          "name:ml": "String",
          "name:mt": "String",
          "name:nl": "String",
          "name:no": "String",
          "name:oc": "String",
          "name:pl": "String",
          "name:pt": "String",
          "name:rm": "String",
          "name:ro": "String",
          "name:ru": "String",
          "name:sk": "String",
          "name:sl": "String",
          "name:sq": "String",
          "name:sr": "String",
          "name:sv": "String",
          "name:ta": "String",
          "name:te": "String",
          "name:th": "String",
          "name:tr": "String",
          "name:uk": "String",
          "name:zh": "String",
          name_de: "String",
          name_en: "String",
          name_int: "String",
          "name:ja_rm": "String",
          "name:latin": "String",
          "name:ja-Hira": "String",
          "name:ja-Latn": "String",
          "name:ja_kana": "String",
          "name:ko-Latn": "String",
          "name:sr-Latn": "String",
          "name:nonlatin": "String",
        },
      },
      {
        id: "globallandcover",
        minzoom: 0,
        maxzoom: 15,
        description:
          "Landcover with generalized polygons up to zoom 9 with classes - Crop, grass, scrub, tree, forest, snow.",
        fields: { class: "String" },
      },
    ],
    crs: "EPSG:3857",
    crs_wkt:
      'PROJCS["WGS 84 / Pseudo-Mercator",GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]],PROJECTION["Mercator_1SP"],PARAMETER["central_meridian",0],PARAMETER["scale_factor",1],PARAMETER["false_easting",0],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["X",EAST],AXIS["Y",NORTH],EXTENSION["PROJ4","+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs"],AUTHORITY["EPSG","3857"]]',
    extent: [
      -20037508.342789244, -20037471.205137063, 20037508.342789244,
      20037471.205137063,
    ],
    tiles: [createURL("/maps/tiles/{z}/{x}/{y}.pbf")],
    logo: createURL("/maps/logo.svg"),
  },
};
