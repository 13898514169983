import { Capacitor } from "@capacitor/core";
import {
  AppUpdate,
  AppUpdateAvailability,
} from "@capawesome/capacitor-app-update";

function isAppUpdatePluginAvailable() {
  // return true if plugin is available and platform is android or ios
  return (
    Capacitor.isPluginAvailable("AppUpdate") && Capacitor.isNativePlatform()
  );
}

export async function requiresUpdate() {
  // if plugin is not available or if
  if (!isAppUpdatePluginAvailable()) return false;
  else {
    const res = await AppUpdate.getAppUpdateInfo();
    return res.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
  }
}

export async function openAppStore() {
  if (isAppUpdatePluginAvailable()) {
    await AppUpdate.openAppStore();
  }
}
