import { Capacitor } from "@capacitor/core";
import {
  ActionPerformed,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";

import router from "./router";
import { type City, CityArray } from "@emblem/common";
import { reloadContent } from "./helpers/content";

export const PushNotificationsData = {
  token: "",
};

async function setNotificationCity(city: City) {
  // TODO: we should make sure PushNotifications.register() finished before we call this
  if (Capacitor.isPluginAvailable("FCM")) {
    // unregister all cities first
    for (const city of CityArray) {
      // TODO: check id unsubscibing from a topic that we are not subscribed to is a problem
      await FCM.unsubscribeFrom({ topic: city.id.toLowerCase() }).then((res) =>
        console.debug(`[FCM] ${res.message}`)
      );
    }
    await FCM.subscribeTo({ topic: city.toLowerCase() }).then((res) =>
      console.debug(`[FCM] ${res.message}`)
    );
  } else {
    throw new Error("FCM plugin not available");
  }
}

export async function setNotificationCityIfPossible(city: City) {
  if (
    Capacitor.isPluginAvailable("PushNotifications") &&
    Capacitor.isPluginAvailable("FCM")
  ) {
    // TODO check if notification permission is granted
    await setNotificationCity(city);
  }
}

if (
  Capacitor.isPluginAvailable("PushNotifications") &&
  Capacitor.isPluginAvailable("FCM")
) {
  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermissions().then((result) => {
    if (result.receive === "granted") {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      // TODO Show some error
    }
  });

  // On success, we should be able to receive notifications
  PushNotifications.addListener("registration", (token: Token) => {
    PushNotificationsData.token = token.value;
    console.log("Push registration success, token: " + token.value);
    // save the FCM token
    FCM.getToken().then((r) => {
      // Get FCM token instead the APN one returned by Capacitor
      console.log("[FCM] token: " + r.token);
      PushNotificationsData.token = r.token;
    });
  });

  // Some issue with our setup and push will not work
  PushNotifications.addListener("registrationError", (error: any) => {
    console.log("Error on registration: " + JSON.stringify(error));
  });

  // Show us the notification payload if the app is open on our device
  // PushNotifications.addListener(
  //   "pushNotificationReceived",
  //   (notification: PushNotificationSchema) => {
  //     console.log("Push received: " + JSON.stringify(notification));
  //   }
  // );

  // Method called when tapping on a notification
  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    async (action: ActionPerformed) => {
      // reload content first to ensure that we are up to date
      await reloadContent();

      const link = action.notification.data.link;
      if (link) {
        const url = new URL(link);
        const route = url.pathname + url.search;
        if (
          decodeURIComponent(router.currentRoute.value.path) == url.pathname
        ) {
          console.log(
            `already at path "${url.pathname}", going back to home before navigating to target route`
          );
          await router.push("/");
        }
        console.log(`navigating to "${route}"`);
        router.push(route);
      }
    }
  );
}
