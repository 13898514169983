import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  gql,
} from "@apollo/client/core";

import {
  type Partner as PartnerInterface,
  type Offer as OfferInterface,
  type Event as EventInterface,
  City,
} from "@emblem/common";

import { store, initializeStore } from "./store";
export { initializeStore };
export type { Store, StoreJSON } from "./store";

const BASE_URL = process.env.SERVER_URL || "";

export const apolloClient = new ApolloClient({
  link: createHttpLink({
    uri: BASE_URL + "/graphql",
  }),
  cache: new InMemoryCache(),
});

export async function getCodes(city: City, email: string): Promise<void> {
  await apolloClient.mutate({
    mutation: gql`
      mutation SendCodes($city: City!, $email: String!) {
        sendCodes(city: $city, email: $email)
      }
    `,
    variables: { city, email },
  });
}

export class Offer implements OfferInterface {
  _id: string;
  type: OfferInterface["type"];
  flash: boolean;
  summary: string;
  description: string | null;
  start: Date;
  end: Date;
  partner: Partner | null = null;
  // front-end properties
  constructor(data?: OfferInterface) {
    this._id = data?._id || "";
    this.type = data?.type || "permanent";
    this.flash = data ? data.flash : false;
    this.summary = data?.summary || "";
    this.description = data?.description || null;
    this.start = data
      ? new Date(data.start)
      : new Date(`${String(new Date().getFullYear())}-09-01`);
    this.end = data
      ? new Date(data.end)
      : new Date(`${String(new Date().getFullYear() + 1)}-09-01`);
  }
  get thumbnail() {
    return this.partner?.thumbnail || null;
  }
  get reclaimed() {
    if (this.type !== "gift") {
      throw new TypeError(`only offers with type "gift" can be reclaimed`);
    }
    return store.reclaimedGifts.has(this._id);
  }
  set reclaimed(value) {
    if (this.type !== "gift") {
      throw new TypeError(`only offers with type "gift" can be reclaimed`);
    }
    if (!this._id)
      throw new TypeError(`offers without an id can't be reclaimed`);
    if (value) store.reclaimedGifts.add(this._id);
    else store.reclaimedGifts.delete(this._id);
  }
  toJSON(): OfferInterface {
    const { _id, type, flash, summary, description, start, end } = this;
    return { _id, type, flash, summary, description, start, end };
  }
  static from(data: OfferInterface) {
    return new Offer(data);
  }
}

export class Partner implements PartnerInterface {
  _id: string;
  city: City;
  name: string;
  categories: PartnerInterface["categories"];
  places: PartnerInterface["places"];
  offers: Offer[];
  constructor(data?: PartnerInterface) {
    this._id = data?._id || "";
    this.city = data?.city ?? City.GRENOBLE;
    this.name = data?.name || "";
    this.categories = data?.categories || [];
    this.places = data?.places || [];
    this.offers = data?.offers.map((offerData) => Offer.from(offerData)) || [];
    this.offers.forEach((offer) => (offer.partner = this));
  }
  get thumbnail() {
    return this._id ? `${BASE_URL}/partners/${this._id}/thumbnail.jpg` : "";
  }
  toJSON(): PartnerInterface {
    const { _id, city, name, categories, places, offers } = this;
    return {
      _id,
      city,
      name,
      categories,
      places,
      offers: offers.map((offer) => offer.toJSON()),
    };
  }
  static from(data: PartnerInterface) {
    return new this(data);
  }
  static async getAll() {
    const partners = await apolloClient
      .query<{ partners: PartnerInterface[] }>({
        query: gql`
          query GetAll {
            partners(city: ${store.city}) {
              _id
              city
              name
              categories
              places {
                name
                address
                location {
                  latitude
                  longitude
                }
                phone
              }
              offers {
                _id
                type
                flash
                summary
                description
                start
                end
              }
            }
          }
        `,
      })
      .then((res) =>
        res.data.partners.map((data) =>
          this.from(
            this.from(
              JSON.parse(JSON.stringify(data), (key, value) =>
                key === "__typename" ? undefined : value
              )
            )
          )
        )
      );
    return partners;
  }
}

export class Event implements EventInterface {
  _id: string;
  city: City;
  title: string;
  content: string;
  start: Date;
  end: Date;
  constructor(data?: EventInterface) {
    this._id = data?._id || "";
    this.city = data?.city ?? City.GRENOBLE;
    this.title = data?.title || "";
    this.content = data?.content || "";
    this.start = data ? new Date(data.start) : new Date();
    this.end = data ? new Date(data.end) : new Date();
  }
  get thumbnail() {
    return this._id ? `${BASE_URL}/events/${this._id}/thumbnail.jpg` : "";
  }
  toJSON(): EventInterface {
    const { _id, city, title, content, start, end } = this;
    return {
      _id,
      city,
      title,
      content,
      start,
      end,
    };
  }
  static from(data: EventInterface) {
    return new this(data);
  }
  static async getAll() {
    const events = await apolloClient
      .query<{ events: EventInterface[] }>({
        query: gql`
          query GetAll {
            events(city: ${store.city}) {
              _id
              city
              title
              content
              start
              end
            }
          }
        `,
      })
      .then((res) =>
        res.data.events.map((data) =>
          this.from(JSON.parse(JSON.stringify(data)))
        )
      );
    return events;
  }
}
